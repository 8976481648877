#popupBox {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10000;
}

#asterick {
  color: red;
}

#sucessDiv {
  margin-top: 1%;
}

#successText {
  margin-left: 0.5%;
  color: #1d8102
}


#textDescription {
  font-size: 13px;
  color: #a19c9c;
  font-weight: bold;
  margin: 10px 0px 5px 0px;
}

#horizontalLine {
  margin: 10px -20px 10px;
  border: 0;
  border-top: 1px solid black;
}

#box {
  position: relative;
  width: 25%;
  height: auto;
  max-height: 70vh;
  margin: calc(100vh - 85vh - 20px) auto 0;
  background: white;
  border-radius: 4px;
  padding: 20px;
  border: 2px solid #FF9900;
  overflow: auto;
}

#closeIcon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  margin-right: 22.5%;
  color: white;
  width: 25px;
  right: calc(15% - 30px);
  top: calc(100vh - 85vh - 33px);
  background-color: #FF9900;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
  font-weight: bold;
}