/*Setting border to none to override the default behaviour of
vis-timeline's .vis-group-level-unknown-but-gte1 class,
where subgroups are bordered with red color.*/
.vis-group-level-unknown-but-gte1 {
    border: none;
}

.vis-item .vis-item-overflow {
    overflow: visible;
}

.vis-item.apollo, .apollo {
    background-color: #FFE082;
}

.vis-item.weblab, .weblab {
    background-color: #81D4FA;
}

.vis-item.mcm, .mcm {
    background-color: #C5E1A5;
}

.vis-item.sev2, .sev2 {
    background-color: #F48FB1;
}

.vis-item.metric, .metric {
    background-color: #D1C4E9;
}

.vis-item {
    border-color: #37474F;
    cursor: pointer;
}

.vis-label.primary-service-group, .primary-service {
    background-color: #ffdbb7;
}

.vis-label.secondary-service-group, .dependency-service {
    background-color: #c8f0ff;
}

.vis-label.lse-group, .lse-tickets-group {
    background-color: #fcbdbd;
}

.flex-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.vis-custom-time {
    pointer-events: none;
}

.color-box {
    height: 20px;
    width: 20px;
}

.toolbox-parent-box {
    margin: 10px;
}